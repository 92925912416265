#intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  max-width: 1000px;
  padding-top: 350px;
  min-height: 100vh;
}

.Typist {
  color: var(--lightest-slate);
  text-align: center;
  font-size: 76px;
  margin-top: -30px;
}
.Typist .Cursor--blinking {
  color: var(--green-bright);
  padding-left: 4px;
}

.intro-title {
  font-family: "NTR", sans-serif;
  font-size: 86px;
}
.intro-name {
  font-family: "NTR", sans-serif;
  color: var(--green-bright);
  font-weight: bold;
  font-size: 86px;
}

.intro-subtitle {
  margin-top: -36px;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  text-align: center;
  font-weight: 300;
  font-size: 48px;
}

.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 22px;
  text-align: center;
  max-width: 600px;
  padding-bottom: 50px;
}

.intro-contact {
  font-size: 22px;
  font-weight: bolder;
  font-family: "NTR", sans-serif;
  align-self: center;
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid var(--green-bright);
  border-radius: 4px;
}

.intro-contact:hover {
  background-color: var(--lightest-navy);
}

b {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  #intro {
    min-height: unset;
    padding-left: unset;
    padding-top: unset;
    margin: 0;
    align-items: center;
  }

  .intro-title {
    font-size: 46px;
  }
  .intro-name {
    font-size: 46px;
  }

  .Typist {
    font-size: 36px;
  }

  .intro-subtitle {
    margin: 0;
    font-size: 28px;
  }

  .intro-desc {
    font-size: 18px;
    max-width: unset;
    padding-bottom: 40px;
  }

  .intro-contact {
    font-size: 16px;
  }
}
